import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  Button,
  ClickAwayListener,
  Modal,
  Menu,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Link,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  SubmitSuppQuoDoc,
  LoadPurchaseOrderDoc,
  CreatePurchaseOrderRequest,
  SetPurchaseMappedDoc,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import { statusFormat } from "../../../../helpers/utils";
import clsx from "clsx";
import FormSubmitQuotation from "../supplierQuotation/formSubmitQuotation";
import PrintQuotation from "../supplierQuotation/printQuotation";
import { SetNotification } from "../../../../store/notification/notification.action";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import RecapQuotation from "../supplierQuotation/recapQuotation";
import DuplicateQuotation from "../supplierQuotation/duplicateQuatation";

export const SuppQuoTable = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "buying"]);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [openRecapQuotModal, setOpenRecapQuotModal] = useState(false);
  const [ openDuplicateQuotModal, setOpenDuplicateQuotModal ] = useState(false)

  const [anchorEl2, setAnchorEl2] = useState(
    Array(props.supplierQuoList.length).fill(null)
  );
  const handleClick2 = (event, index) => {
    const newAnchorEl2 = [...anchorEl2];
    newAnchorEl2[index] = event.currentTarget;
    setAnchorEl2(newAnchorEl2);
  };
  const handleClose2 = (index) => {
    const newAnchorEl2 = [...anchorEl2];
    newAnchorEl2[index] = null;
    setAnchorEl2(newAnchorEl2);
  };
  const handleCancelSupplierQuotation = (row) => {
    const data = {
      doctype: "Supplier Quotation",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/cancel-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("common:CancelQuotationSuccess")}`,
            })
          );

          handleReload();
        }
      });
  };
  const handleReload = () => {
    window.location.reload();
  };

  const handleDeleteSupplierQuotation = (row) => {
    const data = {
      doctype: "Supplier Quotation",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("common:DeleteQuotationSuccess")}`,
            })
          );

          handleReload();
        }
      });
  };

  const handleAmendSupplierQuotation = (row) => {
    const data = {
      doctype: "Supplier Quotation",
      docname: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/amend`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          // dispatch(
          //     SetNotification({ code: "error", message: "Something went wrong" })
          // );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          // dispatch(
          //     SetNotification({
          //       code: "success",
          //       message: `${t("common:DeleteQuotationSuccess")}`,
          //     })
          // );
          handleOpenModal(row);
          //  handleReload();
        }
      });
  };
  const purchaseOrderDoc = useSelector(
    (state) => state.Buying.purchaseOrderDoc
  );

const handleOpenRecapQuotModal = (row) => {
  setSelectedRow(row);
  setOpenRecapQuotModal(true);
}
const handleDuplicateQuotation = (row) => {
  setSelectedRow(row);
  setOpenDuplicateQuotModal(true);
}
const handleCloseRecapQuotModal = () => {
  setOpenRecapQuotModal(false);
}

const handleCloseModal = () => {
  setOpenDetailsModal(false);
  dispatch(SetPurchaseMappedDoc(null));
};
  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleOpenPrintModal = (row) => {
    setSelectedRow(row);
    setOpenPrintModal(true);
  };
  const handleCloseModalQuotation = () => {
    setOpen(false);
  };
  const handleCloseModalPrintQuotation = () => {
    setOpenPrintModal(false);
  };
  const handleCloseModalDuplicateQuotation = () => {
    setOpenDuplicateQuotModal(false);
  }
  const createPurchaseOrder = () => {
    // Get today's date in the desired format
    const today = new Date().toISOString().split('T')[0]; // This will give you YYYY-MM-DD format
    // Add schedule_date to purchaseOrderDoc
    const updatedPurchaseOrderDoc = {
      ...purchaseOrderDoc,
      status:"To Receive and Bill",
      schedule_date: today
    };
    // Dispatch the action with the updated purchaseOrderDoc
    dispatch(CreatePurchaseOrderRequest(updatedPurchaseOrderDoc));
    handleCloseModal();
  };

  const purchaseOrderDetails = (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("buying:CreatePurchaseOrder")}:
          {purchaseOrderDoc?.items[0]?.material_request}
        </Typography>
        <Box mt={3}>
          <Box>
            <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
              <Typography align={"left"} color={"primary"}>
                {t("common:AddBy")}: {purchaseOrderDoc?.owner}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:mgsCible")}: {purchaseOrderDoc?.items[0]?.warehouse}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("buying:SupplierName")}: {purchaseOrderDoc?.supplier_name}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {purchaseOrderDoc?.address_display?.replace(
                  /<br\s*\/?>/gi,
                  ";   "
                )}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:createDate")}: {purchaseOrderDoc?.transaction_date}
              </Typography>
            </Paper>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography
            style={{ marginBottom: -5 }}
            align={"left"}
            color={"primary"}
          >
            {t("common:article")}:
          </Typography>
          <Table className={tableclasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:produit")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:itemCode")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:amnt")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:qtyAchat")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:taux")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:Taxation")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseOrderDoc?.items.map((row, i) => (
                <TableRow className={tableclasses.tableRow} key={i}>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_name}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_code}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.amount.toFixed(3)} {purchaseOrderDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.qty}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.rate.toFixed(3)} {purchaseOrderDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_tax_rate != "" &&
                      Object.keys(JSON.parse(row?.item_tax_rate))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
            <Typography align={"left"} color={"primary"}>
              {t("common:qtyTotal")}: {purchaseOrderDoc?.total_qty}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalHT")}: {purchaseOrderDoc?.total?.toFixed(3)}{" "}
              {purchaseOrderDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalTaxe")}:{" "}
              {purchaseOrderDoc?.base_total_taxes_and_charges?.toFixed(3)}{" "}
              {purchaseOrderDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:grandTotal")}:{" "}
              {purchaseOrderDoc?.grand_total?.toFixed(3)}{" "}
              {purchaseOrderDoc?.currency}
            </Typography>
          </Paper>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ marginTop: 20, alignContent: "center" }}
            variant="contained"
            color="primary"
            onClick={createPurchaseOrder}
          >
            {t("common:valider")}
          </Button>
          <Button
            style={{ marginTop: 20, alignContent: "center", marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            {t("common:fermer")}
          </Button>
        </Box>
      </Box>
    </div>
  );

  const handleSubmit = (id) => {
    dispatch(SubmitSuppQuoDoc(id));
  };

  const handlePurOrderDetails = (id) => {
    dispatch(LoadPurchaseOrderDoc(id));
    setOpenDetailsModal(true);
  };

  return (
    <Box className={tableclasses.paper}>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <FormSubmitQuotation
            setCloseModal={handleCloseModalQuotation}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Modal
        open={openPrintModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenPrintModal ? setOpenPrintModal(false) : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
            }}
          >
            <PrintQuotation
              setCloseModalPrint={handleCloseModalPrintQuotation}
              id={selectedRow}
            />
          </div>
        </ClickAwayListener>
      </Modal>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("buying:SupplierName")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:total")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>

               {t("common:Taxation")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:grandTotal")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.supplierQuoList &&
            Array.isArray(props.supplierQuoList) &&
            props.supplierQuoList.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.transaction_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.supplier}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.total_taxes_and_charges.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.grand_total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell
                  className={clsx(
                    tableclasses.tableCell,
                    tableclasses[statusFormat(row?.status).color]
                  )}
                  align="left"
                >
                  {statusFormat(row?.status).value}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="right">
                  <Box>
                    <Button
                      style={{ marginRight: 5 }}
                      disabled={row?.status !== "Draft"}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSubmit(row?.name)}
                    >
                      {t("common:valider")}
                    </Button>
                    <Button
                      disabled={row?.status === "Draft"}
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handlePurOrderDetails(row?.name)}
                    >
                      {t("buying:CreatePurchaseOrder")}
                    </Button>
                    <Button
                      disabled={row?.status !== "Draft"}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenModal(row)}
                      style={{ marginRight: 5, marginLeft: 5 }}
                    >
                      {/* <VisibilityIcon /> */}
                      {t("common:modifier")}
                    </Button>
                    <Button
                      className={classes.menuButton}
                      size="small"
                      style={{ marginRight: 5 }}
                      variant="outlined"
                      //disabled={row.status !== "Pending"}
                      color="primary"
                      aria-controls={`basic-menu`}
                      aria-haspopup="true"
                      onClick={(event) => handleClick2(event, index)}
                    >
                      {!anchorEl2[index] && <MoreVertIcon />}
                    </Button>
                    <Menu
                      id={`basic-menu-${index}`}
                      anchorEl={anchorEl2[index]}
                      open={Boolean(anchorEl2[index])}
                      onClose={() => handleClose2(index)}
                      MenuListProps={{
                        "aria-labelledby": `basic-menu-${index}`,
                      }}
                    >
                      <MenuItem
                        disabled={
                          row?.status == "Cancelled" ||
                          row?.status == "Draft" ||
                          row?.status == "Completed"
                        }
                        onClick={() => handleCancelSupplierQuotation(row)}
                      >
                        {" "}
                        <CancelIcon style={{ color: "red" }} />{" "}
                      </MenuItem>
                      <MenuItem
                        disabled={row?.status == "Completed" }
                        onClick={() => handleDeleteSupplierQuotation(row)}
                      >
                        {" "}
                        <DeleteIcon style={{ color: "red" }} />{" "}
                      </MenuItem>
                      <MenuItem
                        disabled={
                          row?.status === "Expired" ||
                          row?.status === "Pending" ||
                          row?.status === "Draft" ||
                          row?.status === "Completed"
                        }
                        onClick={() => handleAmendSupplierQuotation(row)}
                      >
                        {" "}
                        <AssignmentReturnIcon
                          style={{ color: "primary" }}
                        />{" "}
                      </MenuItem>
                      <MenuItem
                      //disabled={row?.status === "Completed"}
                      onClick={() => handleDuplicateQuotation(row)}
                    >
                      {" "}
                      <ContentCopyIcon style={{ color: "primary" }} />{" "}
                    </MenuItem>

                      <MenuItem onClick={() => handleOpenPrintModal(row)}>
                        {" "}
                        <LocalPrintshopIcon />{" "}
                      </MenuItem>
                    </Menu>


                    <Button
                      // disabled={row?.status !== "Draft"}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenRecapQuotModal(row)}
                      style={{ marginRight: 5, marginLeft: 5 }}
                    >
                      <ChromeReaderModeIcon />
                    </Button>
                      



                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {purchaseOrderDoc ? (
          purchaseOrderDetails
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>


      <Modal
        open={openRecapQuotModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenRecapQuotModal ? setOpenRecapQuotModal(false) : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
              
            }}
          >
            <RecapQuotation
              setCloseModalPrint={setOpenRecapQuotModal}
              id={selectedRow}
            />
          </div>
        </ClickAwayListener>
      </Modal>

      <Modal
        open={openRecapQuotModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* <ClickAwayListener
          onClickAway={() =>
            setOpenRecapQuotModal ? setOpenRecapQuotModal(false) : null
          }
        > */}
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
              
            }}
          >
            <RecapQuotation
              setCloseModalPrint={setOpenRecapQuotModal}
              id={selectedRow}
            />
          </div>
        {/* </ClickAwayListener> */}
      </Modal>

      <Modal
        open={openDuplicateQuotModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* <ClickAwayListener
          onClickAway={() =>
            setOpenRecapQuotModal ? setOpenRecapQuotModal(false) : null
          }
        > */}
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
              
            }}
          >
            <DuplicateQuotation
              setCloseModal={handleCloseModalDuplicateQuotation}
              id={selectedRow}
            />
          </div>
        {/* </ClickAwayListener> */}
      </Modal>


    </Box>
  );
};
