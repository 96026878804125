import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loadLedgerByCustomer } from '../../../../store/client/client.action';
import { SimpleTable } from "../../../../components/simpleTable";
import { Box, Grid } from '@material-ui/core';
import { Loader } from '../../../../components/loader';
import { fr } from 'date-fns/locale';
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from '../../style';
import moment from 'moment-timezone';
import { formatDate } from '../../../../helpers/utils';

const CustomerLedgerReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    const customerLedger = useSelector((state) => state.Client.clientLedger);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState({
        from_date: moment().subtract(1, 'months').format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD")
    });

    const handleFromDateChange = (e) => {
        setDate({ ...date, from_date : formatDate(e, "YYYY-MM-DD")});
    };

    const handleToDateChange = (e) => {
        setDate({ ...date, to_date : formatDate(e, "YYYY-MM-DD")});
    };
    
    useEffect(() => {
        if(globalDefaults?.default_company && props.client)
        dispatch(loadLedgerByCustomer(props.client, date, globalDefaults?.default_company));
    }, [props.client, date,globalDefaults?.default_company]);
    
    useEffect(() => {
        customerLedger ? setLoading(false) : setLoading(true);
    }, [customerLedger]);

    const columns = [
        {key: "party_name", title: "clients:partyName"},
        {key: "opening_balance", title: "clients:openingBalance"},
        {key: "invoiced_amount", title: "clients:invoicedAmount"},
        {key: "paid_amount", title: "clients:paidAmount"},
        {key: "return_amount", title: "clients:returnAmount"},
        {key: "closing_balance", title: "clients:closingBalance"},
        {key: "currency", title: "common:currency"},
    ];

    return (
        <React.Fragment>
            <Grid container >
                <Box className={classes.formControl} mr={2}>
                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            name="from_date"
                            label="De"
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            id="date-picker-inline"
                            keyboardIcon={<span className={"icon-calendar"} />}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            onChange={handleFromDateChange}
                            value={date.from_date}
                        />
                    </MuiPickersUtilsProvider>
                </Box> 
                <Box className={classes.formControl}>
                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            minDate={moment(date.from_date).add(1, 'd')}
                            name="to_date"
                            label="À"
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            id="date-picker-inline"
                            keyboardIcon={<span className={"icon-calendar"} />}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            onChange={handleToDateChange}
                            value={date.to_date}
                        />
                    </MuiPickersUtilsProvider>
                </Box> 
            </Grid>
            {loading ? <Loader/> :<SimpleTable columns={columns} data={customerLedger && customerLedger[0] ? [customerLedger[0]] : []} title={"customerLedgerReport"} hasPaper={true} />}
        </React.Fragment>
    );
};

export default CustomerLedgerReport;