import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { LoadPurchaseInvoice } from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { PaymentEntryTable } from "../paymentEntryTable";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useHistory } from "react-router";
import * as XLSX from "xlsx";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../../helpers/helper";
import {Button, FormControl, InputAdornment, OutlinedInput,ClickAwayListener,Modal} from "@material-ui/core";
import { Filter } from "../../../../components/filter";
import {flexbox} from "@mui/system";
import ImportPurchaseInvoiceFile from "../purchaseInvoiceTable/importPurchaseInvoice";
import { CloudDownload } from "@material-ui/icons";

export const PaymentEntryRequest = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["buying", "common"]);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const purchaseInvoiceList = useSelector(
    (state) => state.Buying.purchaseInvoiceList
  );
  const [listPurchaseInvoiceList, setListPurchaseInvoiceList] = useState([]);
  const [ openModalImport, setOpenModalImport ] = useState(false)
  const [valueSearch, setValueSearch] = useState("");
  const [filters, setFilters] = useState({
    status: [],
  });
  const handleCloseModalImport = () => {
    setOpenModalImport(false);
  }
  const maxitems = 20;
    const handleNavigation = () => {
        history.push("/purchase-invoice/create");
    };
  const handleSearch = (event) => {
    setPage(1);
    setValueSearch(event.target.value);
    setListPurchaseInvoiceList(
      GlobalfilterData(
        filters?.status?.length > 0
          ? purchaseInvoiceList?.slice(0)?.filter(FilterBy(filters))
          : purchaseInvoiceList,
        event.target.value,
        ["name", "posting_date", "supplier"]
      )
    );
  };
  const addModalImport = () => {
    setOpenModalImport(true);
    }
  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setListPurchaseInvoiceList(
      valueSearch
        ? GlobalfilterData(purchaseInvoiceList, valueSearch, [
            "name",
            "creation",
            "set_warehouse",
          ])
            ?.slice(0)
            ?.filter(FilterBy(FilterDataBy))
        : purchaseInvoiceList?.slice(0)?.filter(FilterBy(FilterDataBy))
    );
  };
  const handleOnExport = () => {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(purchaseInvoiceList)
      XLSX.utils.book_append_sheet(wb, ws, "sheet1")
      XLSX.writeFile(wb, "Facture_Achat.xlsx")
  }
  useEffect(() => {
    if (globalDefaults?.default_company)
      dispatch(LoadPurchaseInvoice(globalDefaults?.default_company));
  }, [globalDefaults?.default_company]);

  if (!purchaseInvoiceList) return <Loader />;

  return (
    <Box>
      <Box className={classes.header}>
          <Button
              className={classes.custombutton}
              size={"small"}
              variant="contained"
              color="primary"
              onClick={handleNavigation}
          >
              {t("buying:CreateNewFactAchat")}
          </Button>
          <Button
            style={{ marginLeft: 10,marginBottom: 10, marginTop: 10 }}
            size="small"
            color="primary"
            variant="outlined"
            onClick={addModalImport}
            startIcon={<CloudDownloadIcon />} // Use the CloudUploadIcon as the start icon
          >
            {t("common:importFile")}
          </Button>
      <Button
        style={{ marginLeft: 10,marginBottom: 10, marginTop: 10 }}
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleOnExport}
        startIcon={<CloudUploadIcon />} // Use the CloudUploadIcon as the start icon
      >
         {t("common:exportFile")}
      </Button>
      <Modal
      open={ openModalImport }
      aria-labelledby="simple-modal-import"
      aria-describedby="simple-modal-import"

    >
        <ClickAwayListener
        onClickAway={() => (setOpenModalImport ? setOpenModalImport(false) : null)}
      >
      <div className={classes.paper} style={{ overflowY: 'auto', maxHeight: '90vh',width: '50%' }}    >
        <ImportPurchaseInvoiceFile setCloseModalImport={handleCloseModalImport}/>
      </div>
         </ClickAwayListener> 
    </Modal>

        <FormControl className={classes.formControlSearch} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={valueSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set(purchaseInvoiceList?.map((item) => item?.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          />
        </Box>
      </Box>
      <Box>
        <PaymentEntryTable
          purchaseInvoiceList={
            valueSearch || filters.status.length > 0
              ? listPurchaseInvoiceList?.slice(
                  (page - 1) * maxitems,
                  page * maxitems
                )
              : purchaseInvoiceList.slice(
                  (page - 1) * maxitems,
                  page * maxitems
                )
          }
        />
        {Math.ceil(
          (valueSearch || filters.status.length > 0
            ? listPurchaseInvoiceList?.length
            : purchaseInvoiceList.length) / maxitems
        ) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(
                (valueSearch || filters.status.length > 0
                  ? listPurchaseInvoiceList?.length
                  : purchaseInvoiceList.length) / maxitems
              )}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
