import React, { useState } from "react";
import clsx from "clsx";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";

import {
  Table,
  ClickAwayListener,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  Menu,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Modal,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createQuotationReq,
  LoadSupplierDetails,
  SetQuotationMappedDoc,
  CreateQuotationRequest,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { statusFormat } from "../../../../helpers/utils";
import CreatePurchaseOrderFromReq from "./createPurchaseOrderFromReq";
import CreateSupplierQuotation from "./createSupplierQuotation";
import CreateRequestForQuotation from "./createRequestForQuotation";
import { SetNotification } from "../../../../store/notification/notification.action";
import { Visibility } from "@material-ui/icons";
import DetailMaterialRequest from "./detailMaterialRequest";
import PrintMaterialReq from "./printMaterialReq";
import DetailMaterialRequestAfterAmend from "./detailMaterialRequestAfterAmend";
import RecapMaterialReq from "./recapMaterialReq";
import DuplicatePurchaseRequest from "./duplicatePurchaseRequest";

export const MatReqTable = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["buying", "common"]);
  const [openQuotationModal, setOpenQuotationModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [message, setMessage] = useState(t("buying:MsgBuying"));
  const [openCreatePurchaseOrderModal, setOpenCreatePurchaseOrderModal] =
    useState(false);
  const [
    openCreateSupplierQuotationModal,
    setOpenCreateSupplierQuotationModal,
  ] = useState(false);
  const [
    openCreateRequestForQuotationModal,
    setOpenCreateRequestForQuotationModal,
  ] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const supplierList = useSelector((state) => state.Buying.supplierList);
  const [loadingButton, setLoadingButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAmend, setOpenAmend] = useState(false);
  const quotationMappedDoc = useSelector(
    (state) => state.Buying.quotationMappedDoc
  );
 
  const selectedSupplierDetails = useSelector(
    (state) => state.Buying.selectedSupplierDetails
  );
  const [anchorEl2, setAnchorEl2] = useState(
    Array(props.purchaseMatReq.length).fill(null)
  );
  const handleClick2 = (event, index) => {
    const newAnchorEl2 = [...anchorEl2];
    newAnchorEl2[index] = event.currentTarget;
    setAnchorEl2(newAnchorEl2);
  };
  const handleClose2 = (index) => {
    const newAnchorEl2 = [...anchorEl2];
    newAnchorEl2[index] = null;
    setAnchorEl2(newAnchorEl2);
  };
  const handleQuotation = (id) => {
    dispatch(createQuotationReq(id));
    setOpenQuotationModal(true);
  };

  const handleChange = (e) => {
    setSelectedSupplier(e.target.value);
    dispatch(LoadSupplierDetails(e.target.value));
  };

  const handleCancelpurchaseOrder = (row) => {
    const data = {
      doctype: "Material Request",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/cancel-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("common:CancelQuotationSuccess")}`,
            })
          );

          handleReload();
        }
      });
  };
  const handleReload = () => {
    window.location.reload();
  };
  const handleDeletepurchaseOrder = (row) => {
    setLoadingButton(true);
    const data = {
      doctype: "Material Request",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("common:DeleteQuotationSuccess")}`,
            })
          );

          handleReload();
        }
      });
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };
  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleOpenModalAmend = (row) => {
    setSelectedRow(row);
    setOpenAmend(true);
  };
  const handleSubmitQuoReq = () => {
    const data = {
      ...quotationMappedDoc,
      status: "Draft",
      message_for_supplier: message,
    };
    data.suppliers = [
      {
        docstatus: 0,
        supplier: selectedSupplierDetails.supplier,
        supplier_name: selectedSupplierDetails.supplier_name,
        contact: selectedSupplierDetails.contact_person,
        email_id: selectedSupplierDetails.contact_email,
        doctype: "Request for Quotation Supplier",
        quote_status: "Pending",
        email_sent: 0,
        send_email: 1,
        parentfield: "suppliers",
        parenttype: "Request for Quotation",
      },
    ];

    dispatch(CreateQuotationRequest(data));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenQuotationModal(false);
    dispatch(SetQuotationMappedDoc(null));
    setSelectedSupplier(null);
    setMessage(t("buying:MsgBuying"));
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickItem = (itemName) => {
    console.log(itemName);
  };

  const quotation = (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("buying:Rquotation")}{" "}
          <strong>{quotationMappedDoc?.items[0]?.material_request}</strong>
        </Typography>
        <Box className={classes.formBlock}>
          <Grid
            container
            spacing={3}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Grid item xs>
              <InputLabel
                required
                className={classes.label}
                error={selectedSupplier ? false : true}
              >
                {t("buying:SelectSupplier")}:
              </InputLabel>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  value={selectedSupplier}
                  IconComponent={ExpandMore}
                  onChange={handleChange}
                  error={selectedSupplier ? false : true}
                  name="supplier"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Array.isArray(supplierList) &&
                    supplierList.map((row, index) => (
                      <MenuItem value={row.name} key={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
                <Typography align={"left"} color={"primary"}>
                  {t("common:AddBy")}: {quotationMappedDoc?.owner}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("common:mgsCible")}:{" "}
                  {quotationMappedDoc?.items[0]?.warehouse}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("buying:RequiredOn")}:{" "}
                  {quotationMappedDoc?.items[0]?.schedule_date}
                </Typography>
              </Paper>
              {selectedSupplier && (
                <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
                  {selectedSupplierDetails ? (
                    <>
                      <Typography align={"left"} color={"primary"}>
                        {t("buying:SupplierName")}:{" "}
                        {selectedSupplierDetails?.supplier_name}
                      </Typography>
                      <Typography align={"left"} color={"primary"}>
                        {t("common:addr")}:{" "}
                        {selectedSupplierDetails?.address_display?.replace(
                          /<br\s*\/?>/gi,
                          ";   "
                        )}
                      </Typography>
                      <Typography align={"left"} color={"primary"}>
                        {t("common:email")}:{" "}
                        {selectedSupplierDetails?.contact_email}
                      </Typography>
                      <Typography align={"left"} color={"primary"}>
                        {t("common:numTel")}:{" "}
                        {selectedSupplierDetails?.contact_mobile}
                      </Typography>
                    </>
                  ) : (
                    <Loader />
                  )}
                </Paper>
              )}
              {selectedSupplier && selectedSupplierDetails && (
                <TextField
                  style={{ marginBottom: 10 }}
                  variant="outlined"
                  multiline
                  rows={5}
                  id="outlined-basic"
                  label={t("buying:MsgSupplier")}
                  name="message"
                  value={message}
                  onChange={handleChangeMessage}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={
              !selectedSupplier
                ? true
                : !selectedSupplierDetails
                ? true
                : message == ""
                ? true
                : false
            }
            style={{ marginTop: 20, alignContent: "center", marginRight: 10 }}
            variant="contained"
            color="primary"
            onClick={handleSubmitQuoReq}
          >
            {t("common:valider")}
          </Button>
          <Button
            style={{ marginTop: 20, alignContent: "center", marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            {t("common:fermer")}
          </Button>
        </Box>
      </Box>
    </div>
  );

  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openRecapReqModal, setOpenRecapReqModal] = useState(false);

  const handleOpenPrintModal = (row) => {
    setSelectedRow(row);
    setOpenPrintModal(true);
  };
  const handleDuplicatepurchaseOrder = (row) => {
    setSelectedRow(row);
    setOpenDuplicateModal(true);
  };

  const handleCloseModalPrintQuotation = () => {
    setOpenPrintModal(false);
  };

  const handleOpenRecapReqModal = (row) => {
    setSelectedRow(row);
    setOpenRecapReqModal(true);
  };
  const handleCloseRecapReqModal = () => {
    setOpenRecapReqModal(false);
  };

  const handleOpenModalCreatePurchaseOrder = (row) => {
    setSelectedRow(row);
    setOpenCreatePurchaseOrderModal(true);
  };
  const handleOpenModalCreateSupplierQuotation = (row) => {
    setSelectedRow(row);
    setOpenCreateSupplierQuotationModal(true);
  };
  const handleOpenModalCreateRequestForQuotation = (row) => {
    setSelectedRow(row);
    setOpenCreateRequestForQuotationModal(true);
  };

  const [anchorEl, setAnchorEl] = useState(
    Array(props.purchaseMatReq.length).fill(null)
  );

  // const handleAmendMaterialRequest = (row) => {
  //   setLoadingButton(true);
  //   const data = {
  //     doctype: "Material Request",
  //     docname: row?.name,
  //   };
  //   fetch(`${process.env.REACT_APP_API_URI}/api/amend`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
  //       Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((res) => {
  //       setLoadingButton(false);
  //       if (!res.ok) {
  //         // dispatch(
  //         //     SetNotification({ code: "error", message: "Something went wrong" })
  //         // );
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       if (data?.success === false) {
  //         setLoadingButton(false);
  //         dispatch(
  //           SetNotification({ code: "warning", message: data?.message })
  //         );
  //       } else {
  //         setLoadingButton(false);
  //         // dispatch(
  //         //     SetNotification({
  //         //       code: "success",
  //         //       message: `${t("common:DeleteQuotationSuccess")}`,
  //         //     })
  //         // );
  //         handleOpenModal(row);
  //       }
  //     });
  // };
  const handleClick = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handleCloseModalPurchaseOrder = () => {
    setOpenCreatePurchaseOrderModal(false);
  };
  const handleCloseModalVisible = () => {
    setOpen(false);
  };
  const handleCloseModalSupplierQuotation = () => {
    setOpenCreateSupplierQuotationModal(false);
  };
  const handleCloseModalAmend = () => {
    setOpenAmend(false);
  };

  const handleCloseModalRequestForQuotation = () => {
    setOpenCreateRequestForQuotationModal(false);
  };

  const handleCloseModalDuplicate = () => {
    setOpenDuplicateModal(false);
  };

  return (
    <Box className={tableclasses.paper}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}.
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("buying:RequiredOn")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:Description")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:itemCode")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:mgsDest")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>{""}</TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.purchaseMatReq &&
            Array.isArray(props.purchaseMatReq) &&
            props.purchaseMatReq.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.transaction_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.schedule_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.title}
                </TableCell>
                
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.item_code}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.set_warehouse}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="right">
                  <div>
                    <div>
                      {/* <Button
                  className={classes.menuButton}
                  size="small"
                  variant="outlined"
                  disabled={row.status !== "Pending"}
                  color="primary"
                  aria-controls={`basic-menu-${index}`}
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, index)}
                >
                  {"create"}
                  {!anchorEl[index] && <ArrowDropDownIcon />}
                </Button> */}
                      {/* <Menu
                  id={`basic-menu-${index}`}
                  anchorEl={anchorEl[index]}
                  open={Boolean(anchorEl[index])}
                  onClose={() => handleClose(index)}
                  MenuListProps={{
                    'aria-labelledby': `basic-menu-${index}`,
                  }}
                >
                  <MenuItem onClick={() => handleOpenModalCreatePurchaseOrder(row)}>Purchase Order </MenuItem>
                  <MenuItem onClick={() => handleOpenModalCreateRequestForQuotation(row)}>Request for Quotation</MenuItem>
                  <MenuItem onClick={() => handleOpenModalCreateSupplierQuotation(row)}>Supplier Quotation</MenuItem>
                </Menu> */}
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  className={clsx(
                    tableclasses.tableCell,
                    tableclasses[statusFormat(row.status).color]
                  )}
                  align="left"
                >
                  {statusFormat(row.status).value}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  <Button
                    className={classes.menuButton}
                    size="small"
                    variant="outlined"
                    disabled={row.status !== "Pending"}
                    color="primary"
                    style={{ marginRight: 5 }}
                    aria-controls={`basic-menu-${index}`}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    {t("common:create")}
                    {!anchorEl[index] && <ArrowDropDownIcon />}
                  </Button>
                  <Button
                    disabled={row?.status !== "Draft"}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(row)}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  >
                    {t("common:valider")}
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenRecapReqModal(row)}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  >
                    <ChromeReaderModeIcon />
                  </Button>
                  <Menu
                    id={`basic-menu-${index}`}
                    anchorEl={anchorEl[index]}
                    open={Boolean(anchorEl[index])}
                    onClose={() => handleClose(index)}
                    MenuListProps={{
                      "aria-labelledby": `basic-menu-${index}`,
                    }}
                  >
                    <MenuItem
                      onClick={() => handleOpenModalCreatePurchaseOrder(row)}
                    >
                      {t("common:PurchaseOrder")}{" "}
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() =>
                        handleOpenModalCreateRequestForQuotation(row)
                      }
                    >
                      {t("common:RequestforQuotation")}
                    </MenuItem> */}
                    <MenuItem
                      onClick={() =>
                        handleOpenModalCreateSupplierQuotation(row)
                      }
                    >
                      {t("common:SupplierQuotation")}
                    </MenuItem>
                  </Menu>
                  {/* <Button
                      style={{ marginRight: 5 }}
                      disabled={row?.status !== "Draft"}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() =>  handleSubmitQuoReq(row?.name)}
                    >
                      {t("common:valider")}
                    </Button> */}
                  {/*<Button
                    disabled={
                      row?.status == "Cancelled" || row?.status == "Draft"
                    }
                    size={"small"}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleQuotation(row.name)}
                  >
                    {t("buying:RequestQuotation")}
                  </Button>*/}
                  <Button
                    className={classes.menuButton2}
                    size="small"
                    style={{ marginLeft: 5 }}
                    variant="outlined"
                    //disabled={row.status !== "Pending"}
                    color="primary"
                    aria-controls={`basic-menu`}
                    aria-haspopup="true"
                    onClick={(event) => handleClick2(event, index)}
                  >
                    {!anchorEl2[index] && <MoreVertIcon />}
                  </Button>
                  <Menu
                    id={`basic-menu-${index}`}
                    anchorEl={anchorEl2[index]}
                    open={Boolean(anchorEl2[index])}
                    onClose={() => handleClose2(index)}
                    MenuListProps={{
                      "aria-labelledby": `basic-menu-${index}`,
                    }}
                  >
                    <MenuItem
                      disabled={
                        row?.status === "Cancelled" ||
                        row?.status === "Draft" ||
                        row?.status === "Completed"
                      }
                      onClick={() => handleCancelpurchaseOrder(row)}
                    >
                      <CancelIcon style={{ color: "red" }} />
                    </MenuItem>
                    <MenuItem
                      disabled={
                        row?.status === "Pending" ||
                        row?.status === "Draft" ||
                        row?.status === "Completed"
                      }
                      onClick={() => handleOpenModalAmend(row)}
                    >
                      <AssignmentReturnIcon
                        style={{ color: "primary" }}
                      />
                    </MenuItem>
                    <MenuItem
                      disabled={row?.status === "Completed"}
                      onClick={() => handleDeletepurchaseOrder(row)}
                    >
                      {" "}
                      <DeleteIcon style={{ color: "red" }} />{" "}
                    </MenuItem>
                    <MenuItem
                      //disabled={row?.status === "Completed"}
                      onClick={() => handleDuplicatepurchaseOrder(row)}
                    >
                      {" "}
                      <ContentCopyIcon style={{ color: "primary" }} />{" "}
                    </MenuItem>

                    <MenuItem onClick={() => handleOpenPrintModal(row)}>
                      {" "}
                      <LocalPrintshopIcon />{" "}
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
          open={openAmend}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
            className={classes.paper}
            style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <DetailMaterialRequestAfterAmend
              setCloseModal={handleCloseModalAmend}
              id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Modal
          open={open}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
            className={classes.paper}
            style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <DetailMaterialRequest
              setCloseModal={handleCloseModalVisible}
              id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Modal
        open={openQuotationModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {quotationMappedDoc ? (
          quotation
        ) : (
          <div className={classes.paper}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 80,
              }}
            >
              <Loader />
            </Box>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ opacity: "70%", marginTop: 80 }}
                variant="outlined"
                color="primary"
                onClick={handleCloseModal}
              >
                {t("common:fermer")}
              </Button>
            </Box>
          </div>
        )}
      </Modal>
      <Modal
        open={openCreatePurchaseOrderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <CreatePurchaseOrderFromReq
            setCloseModal={handleCloseModalPurchaseOrder}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>

      <Modal
        open={openCreateRequestForQuotationModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* <ClickAwayListener
          onClickAway={() =>
            setOpenCreateRequestForQuotationModal
              ? setOpenCreateRequestForQuotationModal(false)
              : null
          }
        > */}
          <div
            className={classes.paper}
            style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
          >
            <CreateRequestForQuotation
              setCloseModal={handleCloseModalRequestForQuotation}
              id={selectedRow}
            />
          </div>
        {/* </ClickAwayListener> */}
      </Modal>

      <Modal
        open={openCreateSupplierQuotationModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <CreateSupplierQuotation
            setCloseModal={handleCloseModalSupplierQuotation}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>

      <Modal
        open={openPrintModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenPrintModal ? setOpenPrintModal(false) : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
            }}
          >
            <PrintMaterialReq
              setCloseModalPrint={handleCloseModalPrintQuotation}
              id={selectedRow}
            />
          </div>
        </ClickAwayListener>
      </Modal>

      <Modal
        open={openDuplicateModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* <ClickAwayListener
          onClickAway={() =>
            setOpenPrintModal ? setOpenPrintModal(false) : null
          }
        > */}
        <div
          className={classes.paper}
          style={{
            overflowY: "auto",
            maxHeight: "90vh",
            width: "auto",
            height: "100%",
            aspectRatio: "1.414",
          }}
        >
          <DuplicatePurchaseRequest
            setCloseModalDuplicate={handleCloseModalDuplicate}
            id={selectedRow}
          />
        </div>
        {/* </ClickAwayListener> */}
      </Modal>

      <Modal
        open={openRecapReqModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenRecapReqModal ? setOpenRecapReqModal(false) : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
            }}
          >
            <RecapMaterialReq
              setCloseModalPrint={handleCloseRecapReqModal}
              id={selectedRow}
            />
          </div>
        </ClickAwayListener>
      </Modal>
    </Box>
  );
};
